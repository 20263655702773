<template>
  <!-- Product Images -->
  <div class="card h-100">
    <div class="card-body">
      <!-- Product Images Header -->
      <h5 class="custom-card-title">
        <i v-bind:class="icon"></i>
        <span>{{ title }}</span>
      </h5>
      <!-- Product Images Header -->
      <hr class="mb-4" />
      <div class="custom-scrollbar uploaded-image-body">
        <Item
          v-for="(file, index) in computedData.files"
          v-bind:key="index"
          v-model="computedData.files[index]"
          v-bind:autoPlay="videoAutoPlay"
          v-bind:metaVisible="metaVisible"
        />
      </div>
      <!-- Add Product Image -->
      <input
        type="file"
        multiple
        ref="fileInput"
        v-on:change="selectFiles"
        style="display: none"
      />
      <div class="text-danger font-14 my-2" v-html="sizeLimitMessage"></div>
      <div class="text-center">
        <span class="btn btn-outline-info" v-on:click="$refs.fileInput.click()">
          <i class="fas fa-cloud-upload-alt f-lg"></i>
          <span class="ml-2">{{ buttonText }}</span>
        </span>
      </div>
      <!-- ./Add Product Image -->
    </div>
  </div>
  <!-- ./Product Images -->
</template>

<script>
import Item from "./components/Item/Index";

export default {
  name: "Multiple",
  components: {
    Item,
  },
  props: {
    data: {
      files: [],
      filesData: null,
    },
    title: {
      default: "Görsel Yükle",
    },
    icon: {
      default: "fas fa-image",
    },
    buttonText: {
      default: "Dosya Seç",
    },
    dragText: {
      default: "Dosya Sürükle & Bırak",
    },
    orText: {
      default: "veya",
    },
    sizeLimit: {
      default: "1024",
    },
    sizeLimitMessage: {
      default: "En fazla <b>1024 KB</b> boyutunda dosya yükleyebilirsiniz.",
    },
    countLimit: {
      default: "10",
    },
    countLimitMessage: {
      default: "En fazla 10 adet dosya yükleyebilirsiniz.",
    },
    metaVisible: {
      default: "true",
    },
    mimeBmp: {
      default: "",
    },
    mimeJpeg: {
      default: "true",
    },
    mimePng: {
      default: "true",
    },
    mimeGif: {
      default: "true",
    },
    mimeWebp: {
      default: "true",
    },
    mimeTiff: {
      default: "",
    },
    mimeSvg: {
      default: "",
    },
    mimeMp4: {
      default: "true",
    },
    mimeMpeg: {
      default: "true",
    },
    mimeOgg: {
      default: "true",
    },
    mimeWebm: {
      default: "true",
    },
    videoAutoPlay: {
      default: "true",
    },
    fixedMetaTitle: {
      default: "",
    },
    fixedMetaAlt: {
      default: "",
    },
  },
  model: {
    prop: "data",
  },
  computed: {
    computedData() {
      return this.data;
    },
    mimeTypes() {
      const result = [];

      helper.setMimeTypes(this._props, result);

      return result;
    },
  },
  methods: {
    selectFiles() {
      const input = this.$refs.fileInput;
      if (input.files.length) {
        if (
          this.data.files.length + input.files.length <=
          parseInt(this.countLimit)
        ) {
          for (let i = 0; i < input.files.length; i++) {
            this.createFile(input.files[i], true);
          }
        } else {
          this.clearInput(
            "Daha fazla dosya yükleyemezsiniz!\n" + this.countLimitMessage
          );
        }
      }
    },
    clearFiles() {
      this.data.files = [];
      this.data.filesData = null;
    },
    newFilesData() {
      this.data.filesData = new FormData();
    },
    clearInput(message, reset) {
      this.$refs.fileInput.value = "";

      if (reset) {
        this.clearFiles();
      }

      if (message) {
        this.$swal({
          title: "Hata",
          html: message,
          icon: "error",
        });
      }
    },
    createFile(file, fromInput) {
      let newFile = {
        id: "",
        temp_id: helper.getRandomInteger(1, 99999),
        metaAlt: this.fixedMetaTitle,
        metaTitle: this.fixedMetaAlt,

        fileName: "",
        fileSize: "",
        fileSizeByte: "",
        fileData: "",
        isImage: false,
        isVideo: false,
        isMain: 0,
        mimeType: "",
      };
      if (this.mimeTypes.indexOf(file.type) >= 0) {
        if (file.size <= parseInt(this.sizeLimit) * 1024) {
          delete newFile.id;
          newFile.fileName = file.name;
          newFile.fileSize = helper.formatSize(file.size);
          newFile.fileSizeByte = file.size;
          newFile.mimeType = file.type;
          newFile.isImage = helper.mimeTypeIsImage(file.type);
          newFile.isVideo = helper.mimeTypeIsVideo(file.type);

          const reader = new FileReader();
          reader.onload = (e) => {
            newFile.fileData = e.target.result;
            newFile.file = file;

            helper.arrayPush(this.data.files, "fileName", newFile);
            this.newFilesData();
            this.data.filesData.append(file.fileName, file);

            if (fromInput) {
              this.checkMainFile();
            }
          };

          reader.readAsDataURL(file);
        } else {
          if (fromInput) {
            this.clearInput(
              "Yüklemeye çalıştığınız dosya boyutu yüksek!\n" +
                this.sizeLimitMessage
            );
          } else {
            this.clearInput();
          }
        }
      } else {
        if (fromInput) {
          this.clearInput(
            "Yüklemeye çalıştığınız dosya türü geçersiz! <br> Desteklenen formatlar: <b>" +
              this.mimeTypes.join(" - ") +
              "<b>"
          );
        } else {
          this.clearInput();
        }
      }
    },
    removeFile(temp_id) {
      helper.arrayRemove(this.data.files, "temp_id", temp_id);
      // this.data.filesData.delete(fileName);

      if (!this.data.files.length) {
        this.clearFiles("", true);
      }

      this.checkMainFile();
      this.createScratch();
      this.clearInput();
    },
    clearMainFile() {
      for (let i = 0; i < this.data.files.length; i++) {
        this.data.files[i].isMain = 0;
      }
    },
    checkMainFile() {
      if (this.data.files.length) {
        let exists = false;
        let index = 0;

        for (let i = 0; i < this.data.files.length; i++) {
          if (this.data.files[i].isMain) {
            index = i;
            exists = true;
            break;
          }
        }

        if (!exists) {
          this.setMainFile(this.data.files[0].temp_id);
        }
      }
    },
    setMainFile(temp_id) {
      this.clearMainFile();

      helper.arrayChange(this.data.files, "temp_id", temp_id, "isMain", 1);

      this.createScratch();
    },
    createScratch() {
      for (let i = 0; i < this.data.files.length; i++) {
        this.createFile(this.data.files[i]);
      }
    },
  },
  mounted() {
    this.$refs.fileInput.setAttribute(
      "accept",
      helper.getAcceptedMimeTypes(this._props)
    );
    this.createScratch();
  },
};
</script>

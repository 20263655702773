<template>
  <!-- Product Image -->
  <div v-bind:class="getClass">
    <div class="card-body uploaded-media">
      <div class="row align-items-center">
        <div class="col">
          <span class="d-block">{{ computedData.fileName }}</span>
          <span class="text-secondary">{{ computedData.fileSize }}</span>
        </div>
        <div class="col-auto">
          <span
            v-if="!computedData.isMain"
            class="btn btn-outline-success font-14"
            v-on:click="setMainFile()"
          >
            <i class="fas fa-check"></i>
          </span>
          <span
            class="btn btn-outline-danger font-14 ml-2"
            v-on:click="removeFile()"
          >
            <i class="fas fa-trash"></i>
          </span>
        </div>
      </div>
      <div class="mb-3 text-center">
        <img
          v-if="!computedData.isVideo"
          v-bind:src="computedData.fileData"
          class="rounded"
          style="object-fit: contain"
        />
        <video
          v-else-if="computedData.isVideo"
          width="160"
          class="rounded"
          v-bind:src="computedData.fileData"
          ref="video"
        ></video>
      </div>
      <div v-if="metaVisible" class="col">
        <input
          type="text"
          class="font-14 form-control"
          placeholder="Image Alt"
          v-model="computedData.metaAlt"
        />
      </div>
      <div v-if="metaVisible" class="col-12 mt-2">
        <input
          type="text"
          class="font-14 form-control"
          placeholder="Image Title"
          v-model="computedData.metaTitle"
        />
      </div>
    </div>
  </div>
  <!-- ./Product Image -->
</template>

<script>
export default {
  name: "Item",
  props: {
    data: null,
    autoPlay: {
      default: "true",
    },
    metaVisible: {
      default: "true",
    },
  },
  model: {
    prop: "data",
  },
  computed: {
    computedData() {
      return this.data;
    },
    getClass() {
      let result = "uploaded-image-card";

      if (this.computedData.isMain) {
        result += " featured";
      }

      return result;
    },
  },
  methods: {
    setMainFile() {
      this.$parent.setMainFile(this.computedData.temp_id);
    },
    removeFile() {
      this.$parent.removeFile(this.computedData.temp_id);
    },
  },
  mounted() {
    if (this.computedData.isVideo && this.autoPlay) {
      const video = this.$refs.video;

      video.setAttribute("autoplay", "true");
      video.setAttribute("loop", "true");
    }
  },
};
</script>
